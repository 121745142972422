<template>
  <notification-drop-down-list-item
    :title="$t('Title')"
    icon="DollarSignIcon"
    color="primary"
    @click-ntf="$emit('redirect', '/billing/history')"
  >
    <small>
      {{ $t('Msg', {
      rechargeThreshold: this.$options.filters.dollar(payload.rechargeThreshold),
      packagePrice: this.$options.filters.dollar(payload.packagePrice),
      }) }}
      <strong>
        <router-link
          :to="'/billing/history'"
          class="link-contextual">
          {{  $t('LinkMsg') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'AutoTopUpActivatedNotification',
  i18n: {
    messages: {
      en: {
        Title: 'Auto top-up has been activated',
        Msg: 'You have reached your {rechargeThreshold} recharge threshold and therefore a {packagePrice} package has been automatically recharged.',
        LinkMsg: 'You can view your invoice here.',
      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
};
</script>

<style scoped>

</style>
