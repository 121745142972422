<template>
  <ul class="bordered-items">
    <li
      v-for="(ntf, index) in notifications"
      :key="index"
      class="flex justify-between px-4 py-4 notification cursor-pointer"
      :class="{ unseen: !ntf.seen }">

      <component
        v-bind:is="getNotificationComponent(ntf.type)"
        :notification="ntf"
        @redirect="$router.push($event)"/>

      <small class="mt-1">
        <template v-if="onlyDate">
          {{ ntf.createdAt | date(true, false, false, false, true, false) }}
        </template>
        <template v-else-if="onlyTime">
          {{ ntf.createdAt | time }}
        </template>
        <template v-else>
          {{ ntf.createdAt| date(true, false, true, false, true, false) }}
        </template>
      </small>
    </li>
  </ul>
</template>

<script>
import enums from '@/enums';

// Components
import ReviewConfigurationNotification from '@/layouts/components/navbar/components/notifications/_components/ReviewConfigurationNotification.vue';
import ExportSuccessNotification from '@/layouts/components/navbar/components/notifications/_components/ExportSuccessNotification.vue';
import InboundSmsNotification from '@/layouts/components/navbar/components/notifications/_components/InboundSmsNotification.vue';
import OptOutNotification from '@/layouts/components/navbar/components/notifications/_components/OptOutNotification.vue';
import OptInNotification from '@/layouts/components/navbar/components/notifications/_components/OptInNotification.vue';
import PrepaidPackagePaymentNotification from '@/layouts/components/navbar/components/notifications/_components/PrepaidPackagePaymentNotification.vue';
import DedicatedNumberPaymentNotification from '@/layouts/components/navbar/components/notifications/_components/DedicatedNumberPaymentNotification.vue';
import DedicatedNumberSubscriptionNotification from '@/layouts/components/navbar/components/notifications/_components/DedicatedNumberSubscriptionNotification.vue';
import CampaignNotification from '@/layouts/components/navbar/components/notifications/_components/CampaignNotification.vue';
import AutoTopUpActivatedNotification from '@/layouts/components/navbar/components/notifications/_components/AutoTopUpActivatedNotification.vue';

/**
 * List to show app notifications
 *
 * @module layouts/components/navbar/components/notifications/NotificationDropDownList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object[]} notifications - notifications to show
 * @vue-prop {boolean} onlyDate - indicate if only show date
 * @vue-prop {boolean} onlyTime - indicate if only show time
 */
export default {
  name: 'NotificationDropDownList',
  i18n: {
    messages: {
      en: {
        [enums.AppNotifications.Type.DEDICATED_NUMBER_PAID]: {
          title: 'Your dedicated number was paid successfully',
        },
      },
    },
  },
  props: {
    notifications: {
      type: Array,
      required: true,
      validator(notifications) {
        return notifications.every(
          (ntf) => ntf && typeof ntf === 'object'
            && 'payload' in ntf
            && 'seen' in ntf
            && 'type' in ntf,
        );
      },
    },
    onlyDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    onlyTime: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getNotificationComponent(type) {
      switch (type) {
        case this.$enums.AppNotifications.Type.REVIEW_CONFIGURATION:
          return ReviewConfigurationNotification;

        case this.$enums.AppNotifications.Type.EXPORT_SUCCESS:
          return ExportSuccessNotification;

        case this.$enums.AppNotifications.Type.INBOUND_SMS:
          return InboundSmsNotification;

        case this.$enums.AppNotifications.Type.OPT_OUT:
          return OptOutNotification;

        case this.$enums.AppNotifications.Type.OPT_IN:
          return OptInNotification;

        case this.$enums.AppNotifications.Type.PREPAID_PACKAGE_PAYMENT_SUCCEED:
        case this.$enums.AppNotifications.Type.PREPAID_PACKAGE_PAYMENT_FAILED:
          return PrepaidPackagePaymentNotification;

        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_PAYMENT_SUCCEED:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_PAYMENT_FAILED:
          return DedicatedNumberPaymentNotification;

        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_ACTIVE:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_PAST_DUE:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_EXPIRED:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_CANCELED:
          return DedicatedNumberSubscriptionNotification;

        case this.$enums.AppNotifications.Type.CAMPAIGN_COMPLETED:
        case this.$enums.AppNotifications.Type.CAMPAIGN_CANCELED:
          return CampaignNotification;

        case this.$enums.AppNotifications.Type.AUTO_TOP_UP_ACTIVATED:
          return AutoTopUpActivatedNotification;

        default:
          return null;
      }
    },
  },
};
</script>
