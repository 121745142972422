<template>
  <notification-drop-down-list-item
    :title="title"
    :icon="icon"
    :color="color"
    @click-ntf="$emit('redirect', '/sms-campaigns/history')"
  >
    <small>
      <span v-html="msg"/>
      <br>
      <strong>
        <router-link
          to="/sms-campaigns/history"
          class="link-plain">
          {{  $t('ViewAll') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'CampaignNotification',
  i18n: {
    messages: {
      en: {
        $Completed: {
          Title: 'Your campaign was completed',
          Msg: 'Your campaign with name <strong>{name}</strong> was successfully completed.',
        },
        $Canceled: {
          Title: 'Your campaign was canceled',
          Msg: 'Your campaign with name <strong>{name}</strong> was canceled due to <strong>{reason}</strong>.',
        },
        ViewAll: 'View sms campaigns history',
      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
  computed: {
    icon() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .CAMPAIGN_COMPLETED
        ? 'SendIcon' : 'AlertCircleIcon';
    },
    color() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .CAMPAIGN_COMPLETED
        ? 'success' : 'danger';
    },
    title() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .CAMPAIGN_COMPLETED
        ? this.$t('$Completed.Title') : this.$t('$Canceled.Title');
    },
    msg() {
      const params = {
        name: this.notification.payload.name,
      };

      return this.notification.type === this.$enums.AppNotifications.Type
        .CAMPAIGN_COMPLETED
        ? this.$t('$Completed.Msg', params)
        : this.$t('$Canceled.Msg', {
          ...params,
          reason: this.$t(`$Enums.Campaign.CancellationReason.${this.payload.reason}`),
        });
    },
  },
};
</script>

<style scoped>

</style>
