<template>
  <notification-drop-down-list-item
    :title="title"
    :icon="icon"
    :color="color"
    @click-ntf="$emit('redirect', '/purchases/my-purchases/my-dedicated-numbers')"
  >
    <small>
      <span v-html="msg"/>
      <br>
      <strong>
        <router-link
          to="/purchases/my-purchases/my-dedicated-numbers"
          class="link-plain">
          {{  $t('ViewAll') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'DedicatedNumberSubscriptionNotification',
  i18n: {
    messages: {
      en: {
        $Active: {
          Title: 'Your dedicated number has been activated',
          Msg: 'Your dedicated number(<strong>{number}</strong>) has been activated successfully.',
        },
        $PastDue: {
          Title: 'Your dedicated number is in past due',
          Msg: 'Your dedicated number(<strong>{number}</strong>) is in past due, please make the payment as soon as possible or your dedicated number may expire.',
        },
        $Expired: {
          Title: 'Your dedicated number has expired',
          Msg: 'Your dedicated number(<strong>{number}</strong>) has expired due to non-payment, you can resubscribe at any time if the number is still available.',
        },
        $Canceled: {
          Title: 'Your dedicated number has been canceled',
          Msg: 'Your dedicated number(<strong>{number}</strong>) has been canceled, you can resubscribe at any time if the number is still available.',
        },
        ViewAll: 'View all dedicated number subscriptions',

      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
  computed: {
    icon() {
      switch (this.notification.type) {
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_ACTIVE:
          return 'CheckCircleIcon';
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_PAST_DUE:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_EXPIRED:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_CANCELED:
          return 'AlertTriangleIcon';

        default:
          return '';
      }
    },
    color() {
      switch (this.notification.type) {
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_ACTIVE:
          return 'success';
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_PAST_DUE:
          return 'warning';
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_EXPIRED:
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_CANCELED:
          return 'danger';

        default:
          return '';
      }
    },
    title() {
      switch (this.notification.type) {
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_ACTIVE:
          return this.$t('$Active.Title');
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_PAST_DUE:
          return this.$t('$PastDue.Title');
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_EXPIRED:
          return this.$t('$Expired.Title');
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_CANCELED:
          return this.$t('$Canceled.Title');

        default:
          return '';
      }
    },
    msg() {
      const params = {
        number: this.$options.filters.phone(
          this.notification.payload.number,
        ),
      };

      switch (this.notification.type) {
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_ACTIVE:
          return this.$t('$Active.Msg', params);
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_PAST_DUE:
          return this.$t('$PastDue.Msg', params);
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_EXPIRED:
          return this.$t('$Expired.Msg', params);
        case this.$enums.AppNotifications.Type.DEDICATED_NUMBER_SUBSCRIPTION_CANCELED:
          return this.$t('$Canceled.Msg', params);

        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>

</style>
