<template>
  <notification-drop-down-list-item
    :title="$t('Title')"
    icon="StarIcon"
    color="primary"
    @click-ntf="$emit('redirect', '/reviews')">
    <small>
      {{ $t('Msg') }}
      <strong>
        <router-link
          :to="'/reviews'"
          class="link-contextual">
          {{  $t('LinkMsg') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';

// mixins
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'ReviewConfigurationNotification',
  i18n: {
    messages: {
      en: {
        Title: 'Reviews configuration',
        Msg: 'Remember, you can configure your reviews.',
        LinkMsg: 'Click here to configure them.',
      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
};
</script>

<style scoped>

</style>
