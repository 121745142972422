<template>
  <notification-drop-down-list-item
    :title="title"
    :icon="icon"
    :color="color"
    @click-ntf="$emit('redirect', '/caller-ids')"
  >
    <small>
      <span v-html="msg"/>
      <br>
      <strong>
        <router-link
          to="/caller-ids"
          class="link-plain">
          {{  $t('ViewAll') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'DedicatedNumberPaymentNotification',
  i18n: {
    messages: {
      en: {
        $Succeed: {
          Title: 'Your dedicated number was paid successfully',
          Msg: 'Your payment of <strong>{invoiceAmount}</strong> associated with the <strong>{invoiceNumber}</strong> invoice for your dedicated number <strong>{number}</strong> was processed successfully.',
        },
        $Failed: {
          Title: 'Your dedicated number could not be paid',
          Msg: 'Your payment of <strong>{invoiceAmount}</strong> associated with the <strong>{invoiceNumber}</strong> invoice for your dedicated number <strong>{number}</strong> could not be processed.',
        },
        ViewAll: 'View all caller numbers',

      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
  computed: {
    icon() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .DEDICATED_NUMBER_PAYMENT_SUCCEED
        ? 'CheckIcon' : 'XIcon';
    },
    color() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .DEDICATED_NUMBER_PAYMENT_SUCCEED
        ? 'success' : 'danger';
    },
    title() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .DEDICATED_NUMBER_PAYMENT_SUCCEED
        ? this.$t('$Succeed.Title') : this.$t('$Failed.Title');
    },
    msg() {
      const params = {
        number: this.$options.filters.phone(
          this.notification.payload.number,
        ),
        invoiceNumber: this.notification.payload.invoiceNumber,
        invoiceAmount: this.$options.filters.dollar(this.notification.payload.invoiceAmount / 100),
      };

      return this.notification.type === this.$enums.AppNotifications.Type
        .DEDICATED_NUMBER_PAYMENT_SUCCEED
        ? this.$t('$Succeed.Msg', params) : this.$t('$Failed.Msg', params);
    },
  },
};
</script>

<style scoped>

</style>
