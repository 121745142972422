<template>
  <div class="flex items-start"
  @click="$emit('click-ntf')">
    <feather-icon
      :icon="icon"
      :svgClasses="[`text-${color}`, 'stroke-current mr-1 h-6 w-6']"/>
    <div class="mx-2">
      <span
        class="font-medium block notification-title">
        {{ title }}
      </span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationDropDownListItem',
  props: {
    icon: {
      type: String,
      require: true,
    },
    color: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped>

</style>
