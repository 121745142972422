<template>
  <notification-drop-down-list-item
    :title="title"
    :icon="icon"
    :color="color"
    @click-ntf="$emit('redirect', '/purchases/my-purchases/my-prepaid-packages')">
    <small>
      <span v-html="msg"/>
      <br>
      <strong>
        <router-link
          to="/purchases/my-purchases/my-prepaid-packages"
          class="link-plain">
          {{  $t('ViewAll') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'PrepaidPackagePaymentNotification',
  i18n: {
    messages: {
      en: {
        $Succeed: {
          Title: 'Your prepaid package was paid successfully',
          Msg: 'Your payment of <strong>{invoiceAmount}</strong> associated with the <strong>{invoiceNumber}</strong> invoice for the <strong>{prepaidPackageAmount}</strong> prepaid package was processed successfully.',
        },
        $Failed: {
          Title: 'Your prepaid package could not be paid',
          Msg: 'Your payment of <strong>{invoiceAmount}</strong> associated with the <strong>{invoiceNumber}</strong> invoice for the <strong>{prepaidPackageAmount}</strong> prepaid package could not be processed.',
        },
        ViewAll: 'View all prepaid packages',
      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
  computed: {
    icon() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .PREPAID_PACKAGE_PAYMENT_SUCCEED
        ? 'CheckIcon' : 'XIcon';
    },
    color() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .PREPAID_PACKAGE_PAYMENT_SUCCEED
        ? 'success' : 'danger';
    },
    title() {
      return this.notification.type === this.$enums.AppNotifications.Type
        .PREPAID_PACKAGE_PAYMENT_SUCCEED
        ? this.$t('$Succeed.Title') : this.$t('$Failed.Title');
    },
    msg() {
      const params = {
        prepaidPackageAmount: this.$options.filters.dollar(
          this.notification.payload.prepaidPackageAmount,
        ),
        invoiceNumber: this.notification.payload.invoiceNumber,
        invoiceAmount: this.$options.filters.dollar(this.notification.payload.invoiceAmount / 100),
      };

      return this.notification.type === this.$enums.AppNotifications.Type
        .PREPAID_PACKAGE_PAYMENT_SUCCEED
        ? this.$t('$Succeed.Msg', params) : this.$t('$Failed.Msg', params);
    },
  },
};
</script>

<style scoped>

</style>
