export default {
  props: {
    notification: {
      type: Object,
      required: true,
      validator(ntf) {
        return ntf && typeof ntf === 'object'
          && 'payload' in ntf
          && 'seen' in ntf
          && 'type' in ntf;
      },
    },
  },
  computed: {
    payload() {
      return this.notification.payload || {};
    },
  },
};
