<template>
  <notification-drop-down-list-item
    :title="$t('Title')"
    icon="UserCheckIcon"
    color="success"
    @click-ntf="$emit('redirect', '/contacts')"
  >
    <small>
      {{ $t('Msg', { from: notification.payload.from }) }}
      <br>
      <strong>
        <router-link
          to="/contacts"
          class="link-plain">
          {{  $t('ViewAll') }}
        </router-link>
      </strong>
    </small>
  </notification-drop-down-list-item>
</template>

<script>
// Components
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';

// Mixins
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'OptInNotification',
  i18n: {
    messages: {
      en: {
        Title: 'Contact opt-in',
        Msg: 'A contact has opted in from {from}',
        ViewAll: 'View contacts list',
      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
};
</script>

<style scoped>

</style>
