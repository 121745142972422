<template>
  <notification-drop-down-list-item
    :title="$t('Title')"
    icon="DownloadCloudIcon"
    color="success"
    @click-ntf="openDownload()"
  >
    <small v-html="$t(message, {
              entity: $tc(`$Entities.${notification.payload.entity}`, 2),
              url: notification.payload.preSignedUrl,
            })">
    </small>
  </notification-drop-down-list-item>
</template>

<script>
import moment from 'moment';

// Components
import NotificationDropDownListItem from '@/layouts/components/navbar/components/notifications/NotificationDropDownListItem.vue';

// Mixins
import notificationItemMixin from '@/layouts/components/navbar/components/notifications/mixins/notification-item.mixin';

export default {
  name: 'ExportSuccessNotification',
  i18n: {
    messages: {
      en: {
        Title: 'Export success',
        Msg: 'A process has been completed to export {entity}. <strong>Click <a href="{url}" class="link-contextual">here</a> to download.</strong>',
        MsgUnavailable: 'A process has been completed to export {entity}. <strong>The link is not available anymore.</strong>',
      },
    },
  },
  components: {
    NotificationDropDownListItem,
  },
  mixins: [notificationItemMixin],
  computed: {
    message() {
      return this.getDaysOffset(this.notification.createdAt) > 29
        ? 'MsgUnavailable' : 'Msg';
    },
  },
  methods: {
    getDaysOffset(createdAt) {
      const currentDate = moment.utc(new Date());
      const createdAtDate = moment.utc(createdAt);

      return currentDate.diff(createdAtDate, 'days');
    },
    openDownload() {
      window.open(this.notification.payload.preSignedUrl, '_blank');
    },
  },
};
</script>

<style scoped>

</style>
